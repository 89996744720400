// @ts-check
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import config from "../../../../config/config.json";
import {DATE_FORMATS, DateContext} from "../../../contexts/dates";
import usePrevious from "../../../hooks/usePrevious";
import {selectCurrentOrganizationId} from "../../../redux/app_selectors";
import {convertHourMinuteDateTimeToHours} from "../../../utils/luxon_helpers";
import sortLocation from "../../../utils/sort_location";
import {selectThemeLocationInfos, selectThemeStartTimeOfTheDay} from "../../fe_settings/fe_settings_selectors";
import {selectRooms} from "../../rooms/rooms_selectors";
import CommonSelector from "../../shared/common_selector/common_selector";
import CommonTextField from "../../shared/common_text_field/common_text_field";
import DateRange from "../../shared/date_range/date_range";
import DeleteConfirmation from "../../shared/delete_confirmation/delete_confirmation";
import InfoBlock from "../../shared/info_block/info_block";
import {getOptionsSlotIntervalWithoutUserDefined} from "../../shared/radio_buttons/options_slot_interval";
import TimeRange from "../../shared/time_range/time_range";
import {
    createRoomBlockerAction,
    deleteRoomBlockerAction,
    updateRoomBlockerAction,
    validateRoomBLockerAction
} from "../room_planner_details_actions";
import {selectAdded, selectConflicts, selectDeleted, selectPatched} from "../room_planner_details_selectors";
import ConflictList from "./conflict_list";
import useStyles from "./room_planner_details_form.styles";
import RoomSelect from "./room_select";

/**
 * RoomPlannerDetailsForm
 * @param {Object} props
 * @param {AdjustedRoomBlockerSlot} props.slot
 * @param {function} props.setPendingType
 * @return {React.ReactElement}
 */
const RoomPlannerDetailsForm = ({slot, setPendingType}) => {
    const {classes, cx} = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {now, startOf, endOf, plusDT, setDT, getDT, format, areSame, fromISO} = useContext(DateContext);

    // Redux
    const rooms = useSelector(selectRooms).rooms;
    const startTimeOfTheDay = useSelector(selectThemeStartTimeOfTheDay);
    const organizationId = useSelector(selectCurrentOrganizationId);
    const conflicts = useSelector(selectConflicts({id: slot.isNew ? slot.createdAt : slot.blockerId}));
    const deletedIds = useSelector(selectDeleted); // array
    const addedId = useSelector((state) => selectAdded(state, {id: slot.createdAt})); // only set if newly saved
    const patchedId = useSelector((state) => selectPatched(state, {id: addedId || slot.blockerId})); // only set if newly saved
    const locationInfos = useSelector(selectThemeLocationInfos);

    // States
    // The form values (interval, reason, selectedRoom, period, time) are only set once from the props as initial value (Not to use useEffect since the values are kept within this component)
    const [isValid, setIsValid] = useState(false);
    const [validateTrigger, setValidateTrigger] = useState(null);
    const [interval, setInterval] = useState(slot.interval || "none");
    const [reason, setReason] = useState(slot.reason);
    const [selectedRoom, setSelectedRoom] = useState(slot.roomIdHash);
    const [errorMessage, setErrorMessage] = useState(null);
    const [isFormPristine, setIsFormPristine] = useState(false);

    const [previousRoom, setPreviousRoom] = useState(slot.roomIdHash); /** @description it is used to check if the room is changed */

    const previousReason = usePrevious(reason);

    const isSeries = interval !== "none";

    const [period, setPeriod] = useState({start: startOf(slot.start, "day"), end: endOf(slot.end, "day")});
    const startOfTheDay = startOf(now(), "day");
    const [time, setTime] = useState({
        start: slot.isNew
            ? plusDT(startOfTheDay, "hour", startTimeOfTheDay || config.DEFAULT_START_TIME)
            : setDT(startOfTheDay, {
                  hour: getDT(slot.start, "hour"),
                  minute: getDT(slot.start, "minute")
              }),
        end: slot.isNew
            ? plusDT(startOfTheDay, "hour", startTimeOfTheDay || config.DEFAULT_END_TIME)
            : setDT(startOfTheDay, {
                  hour: getDT(slot.end, "hour"),
                  minute: getDT(slot.end, "minute")
              })
    });

    const validate = () => {
        // date time valid
        if (!period.start.isValid || !period.end.isValid || !time.start.isValid || !time.end.isValid) {
            setErrorMessage(t("RoomPlanner.dateTimeInvalid"));
            return false;
        }

        // start date < end date
        if (period.start > endOf(period.end, "day")) {
            setErrorMessage(t("RoomPlanner.startDateMustFuture"));
            return false;
        }

        // start time > end time on same day
        const endHour = convertHourMinuteDateTimeToHours(time.end);
        const startHour = convertHourMinuteDateTimeToHours(time.start);
        if (endHour <= startHour) {
            setErrorMessage(t("RoomPlanner.startTimeMustFuture"));
            return false;
        }

        // room
        if (!selectedRoom) {
            setErrorMessage(t("RoomPlanner.roomNeeded"));
            return false;
        }

        // reason
        if (!reason || reason.length === 0) {
            setErrorMessage(t("RoomPlanner.reasonNeeded"));
            return false;
        }

        setErrorMessage(null);
        return Boolean(selectedRoom);
    };

    useEffect(() => {
        if (isFormPristine) {
            const newIsValid = validate();
            setIsValid(newIsValid);
            if (newIsValid) {
                setValidateTrigger(new Date());
            }
        }
    }, [interval, period, time, selectedRoom, reason]);

    useEffect(() => {
        if (isValid || (previousReason === "" && reason !== "")) {
            const slotParam = {...slot};
            if (addedId) {
                slotParam.blockerId = addedId;
            }
            dispatch(validateRoomBLockerAction(organizationId, selectedRoom, slotParam, getData()));
        }
    }, [validateTrigger]);

    const getData = () => ({
        selectedRoom,
        startDate: format(startOf(period.start, "day"), DATE_FORMATS.ISO_DATE),
        endDate: format(endOf(period.end, "day"), DATE_FORMATS.ISO_DATE),
        hourStartLocalTime: convertHourMinuteDateTimeToHours(time.start),
        hourEndLocalTime: convertHourMinuteDateTimeToHours(time.end),
        interval
    });

    const handleChangePeriod = (date, name) => {
        setIsFormPristine(true);
        if (name === "start" && interval === "none" && date.isValid) {
            setPeriod({start: date, end: date});
        } else {
            setPeriod((prevState) => ({
                ...prevState,
                [name]: date
            }));
        }
    };
    const handleChangeTime = (date, name) => {
        setIsFormPristine(true);
        setTime((prevState) => ({
            ...prevState,
            [name]: date
        }));
    };

    /**
     * Handler for change the interval
     * *@param {object} event
     * *@param {object} event.target
     */
    const handleChangeInterval = ({target}) => {
        setIsFormPristine(true);
        setInterval(target.value);
    };

    /**
     * Handler for delete a room blocker
     */
    const handleDelete = () => {
        // Set most latest id from the redux store
        const id = patchedId || slot.blockerId || addedId;
        dispatch(deleteRoomBlockerAction(slot.roomIdHash, id, organizationId, isSeries));
        setPendingType("delete");
    };

    /**
     * Handler for save or update a room blocker
     */
    const handleSave = () => {
        const start = setDT(period.start, {
            hour: getDT(time.start, "hour"),
            minute: getDT(time.start, "minute"),
            second: 0,
            millisecond: 0
        });
        const end = setDT(period.end, {
            hour: getDT(time.end, "hour"),
            minute: getDT(time.end, "minute"),
            second: 0,
            millisecond: 0
        });

        const params = {
            startDate: format(start, DATE_FORMATS.ISO_DATE),
            endDate: format(end, DATE_FORMATS.ISO_DATE),
            reason: reason,
            interval
        };

        if (slot.isNew && !addedId) {
            params["hourStartLocalTime"] = convertHourMinuteDateTimeToHours(time.start);
            params["hourEndLocalTime"] = convertHourMinuteDateTimeToHours(time.end);
            dispatch(createRoomBlockerAction(selectedRoom, params, organizationId, slot.createdAt));
        } else {
            // workaround because feature of updating a room is not existing in backend
            if (selectedRoom !== previousRoom) {
                setPreviousRoom(selectedRoom);
                dispatch(deleteRoomBlockerAction(previousRoom, slot.blockerId || addedId, organizationId, true));
                dispatch(createRoomBlockerAction(selectedRoom, params, organizationId, slot.createdAt));
            } else {
                // Set most latest id from the redux store
                const id = addedId || slot.blockerId;
                const originalId = addedId || slot.blockerId;
                dispatch(updateRoomBlockerAction(selectedRoom, id, params, organizationId, originalId));
            }
        }
        setPendingType("save");
    };

    const handleChangeReason = (event) => {
        setIsFormPristine(true);
        setReason(event.target.value);
    };
    const handleChangeRoom = (selectedRoom) => {
        setIsFormPristine(true);
        setSelectedRoom(selectedRoom);
    };

    /**
     * Check if the value has been changed after save
     * @return {boolean}
     */
    const isChanged = () => {
        let changed = false;

        // Check if start date has been changed
        if (!areSame(slot.start, period.start, "day")) changed = true;

        // Check if end date has been changed
        if (!areSame(slot.end, period.end, "day")) changed = true;

        // Check if start time has been changed
        const originalStart = convertHourMinuteDateTimeToHours(slot.start);
        const currentStart = convertHourMinuteDateTimeToHours(time.start);
        if (originalStart !== currentStart) changed = true;

        // Check if end time has been changed
        const originalEnd = convertHourMinuteDateTimeToHours(slot.end);
        const currentEnd = convertHourMinuteDateTimeToHours(time.end);
        if (originalEnd !== currentEnd) changed = true;

        // Check if interval has been changed
        if (slot.interval !== interval) changed = true;

        // Check if reason has been changed
        if (slot.reason !== reason) changed = true;

        return changed;
    };

    // create room select menu
    const options = [];
    for (const room of rooms) {
        options.push({name: room.name, id: room._id, locationId: room.id});
    }
    const sortedOptions = [...options].sort(sortLocation(locationInfos, "locationId"));

    return (
        <form
            className={cx(
                {
                    [classes.hide]: deletedIds.includes(slot.blockerId) || deletedIds.includes(addedId)
                },
                classes.form
            )}
        >
            <div className={classes.root}>
                <div className={classes.block}>
                    <strong>{t("RoomPlanner.block")}</strong>
                    <div data-testid="deleteConfirmation">
                        <DeleteConfirmation
                            disabled={(slot.isNew && !addedId) || !selectedRoom}
                            trigger={{
                                color: "primary",
                                label: t("RoomPlanner.delete")
                            }}
                            onConfirm={handleDelete}
                        >
                            {t("RoomPlanner.delete_confirmation")}
                        </DeleteConfirmation>
                    </div>
                </div>
                <div className={classes.error}>{errorMessage && errorMessage}</div>
                <div className={classes.formWrapper}>
                    <div className={classes.formSection}>
                        <div className={classes.formGroup}>
                            <div className={classes.formControl}>
                                <RoomSelect
                                    className={classes.fullWidth}
                                    disabled={false}
                                    label={t("RoomPlanner.room")}
                                    options={sortedOptions}
                                    placeholder={t("RoomPlanner.placeholder")}
                                    value={selectedRoom}
                                    onChange={handleChangeRoom}
                                />
                            </div>
                        </div>
                        <div className={classes.formGroup}>
                            <div className={classes.formControl}>
                                <DateRange
                                    disabled={{start: false, end: interval === "none"}}
                                    min={startOf(fromISO(config.MIN_SLOT_DATE), "day")}
                                    styles={{inputDate: classes.inputDate, marginBetween: classes.marginBetween}}
                                    title={t("RoomPlanner.date")}
                                    values={period}
                                    onChange={handleChangePeriod}
                                />
                            </div>
                        </div>
                        <div className={classes.formGroup}>
                            <div className={classes.formControl}>
                                <TimeRange
                                    styles={{inputDate: classes.inputDate, marginBetween: classes.marginBetween}}
                                    title={t("RoomPlanner.time")}
                                    values={time}
                                    onChange={handleChangeTime}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={classes.formSection}>
                        <div className={classes.formControl}>
                            <CommonSelector
                                disableReset
                                fullWidth
                                items={getOptionsSlotIntervalWithoutUserDefined().map((option) => ({
                                    label: t(option.label),
                                    value: option.value
                                }))}
                                styles={{input: classes.fullWidth}}
                                title={t("RoomPlanner.interval")}
                                value={interval}
                                onChange={handleChangeInterval}
                            />
                        </div>
                    </div>
                    <div className={classes.formSection}>
                        <div className={classes.formGroup}>
                            <div className={cx(classes.formControl, classes.textFieldWrapper)}>
                                <CommonTextField
                                    minRows={4}
                                    multiline
                                    title={t("RoomPlanner.addReason")}
                                    value={reason}
                                    onChange={handleChangeReason}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={classes.thinDivider} />
                    <div className={classes.blockSection}>
                        <InfoBlock title={t("RoomPlanner.conflicts", {count: conflicts.length})}>
                            <ConflictList list={conflicts} />
                        </InfoBlock>
                    </div>
                </div>
            </div>
            <div className={classes.divider} />

            <div className={classes.actions}>
                <Button
                    className={classes.button}
                    color="primary"
                    data-testid="saveButtonFormRooms"
                    disabled={!isValid || !isChanged() || !!conflicts.length}
                    size="small"
                    variant="contained"
                    onClick={handleSave}
                >
                    {t("RoomPlanner.save")}
                </Button>
            </div>
            <div className={classes.divider} />
        </form>
    );
};

RoomPlannerDetailsForm.propTypes = {
    slot: PropTypes.shape({
        blockerId: PropTypes.string,
        roomIdHash: PropTypes.string,
        name: PropTypes.string,
        seriesId: PropTypes.string,
        reason: PropTypes.string,
        start: PropTypes.object, // DateTime object
        end: PropTypes.object, // DateTime object
        interval: PropTypes.string,
        isNew: PropTypes.bool,
        createdAt: PropTypes.string
    }),
    setPendingType: PropTypes.func.isRequired
};

export default RoomPlannerDetailsForm;
