import {makeStyles} from "tss-react/mui";

/** @type {Object} */
export default makeStyles()((theme) => ({
    root: {
        position: "relative",
        minHeight: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column"
    },
    form: {
        height: "100%"
    },
    formWrapper: {
        display: "flex",
        height: "100%",
        flexDirection: "column",
        flexFlow: "wrap",
        justifyContent: "start"
    },
    formSection: {
        display: "inline-flex",
        flexDirection: "column",
        alignItems: "start",
        width: "100%"
    },
    blockSection: {
        marginTop: "1.875rem", // 30px
        width: "100%",
        fontSize: "0.85rem"
    },
    intervalSection: {
        minWidth: "12rem",
        zIndex: 2
    },
    conflictsSection: {
        margin: "1rem 0"
    },
    datesSection: {
        marginTop: theme.spacing("m")
    },
    formGroup: {
        display: "inline-flex",
        flexDirection: "row",
        alignItems: "start",
        width: "100%"
    },
    formControl: {
        "paddingTop": theme.spacing("s"),
        "marginLeft": theme.spacing("m"),
        "paddingBottom": theme.spacing("m"),
        "display": "flex",
        "width": "100%",

        "&:first-of-type": {
            marginLeft: 0
        },

        "& .MuiInputLabel-root": {
            fontSize: "0.875rem"
        },
        "& .MuiSelect-icon": {
            color: theme.palette.primary.main,
            top: "calc(50% - 14px)"
        },
        "& .Mui-disabled": {
            color: theme.palette.text.disabled
        },
        "& .MuiFormControl-root": {
            flexShrink: "0"
        },
        "& .MuiFormHelperText-root.Mui-error": {
            fontSize: "0.688rem"
        }
    },
    divider: {
        width: "calc(100% + 5rem)",
        height: "1px",
        position: "relative",
        left: "-2.5rem",
        background: `${theme.palette.grey[100]} 0% 0% no-repeat padding-box`
    },
    thinDivider: {
        width: "calc(100% + 5rem)",
        height: "1px",
        position: "relative",
        left: "-2.5rem",
        background: `${theme.palette.grey[100]} 0% 0% no-repeat padding-box`
    },
    input: {
        height: "1.5rem",
        fontSize: "0.875rem",
        color: theme.palette.text.primary,
        padding: `0.4rem 0`,
        width: "12rem"
    },
    button: {
        ...theme.custom.rightPanel.button
    },
    inputCustomFrequency: {
        width: "100%",
        height: "1.5rem",
        fontSize: "0.75rem",
        color: theme.palette.text.primary,
        padding: `${theme.spacing("xxs")} 0`
    },
    menu: {
        fontSize: "0.875rem"
    },
    ml1: {
        marginLeft: theme.spacing("m")
    },
    mt2: {
        marginTop: theme.spacing("l")
    },
    disabled: {
        color: theme.palette.text.disabled
    },
    dateButton: {
        fontSize: "0.8rem",
        width: "1.5rem",
        height: "1.5rem",
        marginRight: "0.2rem",
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightBold,
        border: `2px solid ${theme.palette.primary.main}`
    },
    selected: {
        "background": theme.palette.common.black,
        "color": theme.palette.common.white,
        "border": `2px solid ${theme.palette.common.black}`,
        "&:hover": {
            background: `${theme.palette.grey[700]} !important`
        }
    },
    next5: {
        fontSize: "0.75rem",
        color: theme.palette.grey[900],
        marginTop: theme.spacing("xs")
    },
    conflicts: {
        fontSize: "0.75rem",
        color: theme.palette.error.main,
        marginTop: theme.spacing("xxs")
    },
    switch: {
        "& .MuiFormControlLabel-root": {
            margin: "-0.25rem",
            padding: `${theme.spacing("xs")} 0`
        }
    },
    switchText: {
        fontSize: "0.875rem",
        fontWeight: theme.typography.fontWeightRegular,
        color: theme.palette.text.primary
    },
    switchTextSelected: {
        color: theme.palette.common.black
    },
    sectionHeader: {
        color: theme.palette.primary.main,
        marginBottom: theme.spacing("s"),
        fontSize: "0.75rem",
        display: "flex",
        cursor: "pointer"
    },
    fullWidth: {
        width: "100%"
    },
    actions: {
        position: "relative",
        display: "inline-flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
        height: "80px",
        width: "100%"
    },
    error: {
        color: theme.palette.error.main,
        fontSize: "0.688rem",
        marginTop: theme.spacing("s"),
        display: "flex",
        flexDirection: "column",
        maxWidth: "fit-content"
    },
    info: {
        fontSize: "0.688rem",
        marginTop: theme.spacing("s")
    },
    inputDate: {
        width: "11.5rem"
    },
    marginBetween: {
        marginLeft: theme.spacing("m")
    },
    block: {
        display: "flex",
        justifyContent: "space-between",
        margin: "1rem 0"
    },
    icon: {
        color: theme.palette.primary.main,
        fontSize: theme.spacing("m")
    },
    hide: {
        display: "none"
    }
}));
