// @ts-check
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import usePrevious from "../../../hooks/usePrevious";
import {reloadRoomPlannerAction} from "../../../pages/room_planner/room_planner_actions";
import {isPending, isRejected, isResolved} from "../../../redux/utils/status";
import RoomPlannerDetailsDialog from "../components/room_planner_details_dialog";
import {selectStatus} from "../room_planner_details_selectors";

/**
 * RoomPlannerDetails
 * @param {Object} props
 * @param {Array<AdjustedRoomBlockerSlot>} props.slots
 * @param {function} props.setMessage
 * @param {function} props.onAdd
 * @return {React.ReactElement}
 */
const RoomPlannerDetails = ({slots, setMessage, onAdd}) => {
    const dispatch = useDispatch();
    const status = useSelector(selectStatus);
    const previousStatus = usePrevious(status);
    const [pendingType, setPendingType] = useState(null);
    const {t} = useTranslation();

    useEffect(() => {
        if (isPending(previousStatus) && isResolved(status) && pendingType === "save") {
            setPendingType(null);
            dispatch(reloadRoomPlannerAction(true));
            setMessage({severity: "success", text: t("RoomPlanner.saved")});
        }
    }, [previousStatus, status]);

    useEffect(() => {
        if (isPending(previousStatus) && isResolved(status) && pendingType === "delete") {
            setPendingType(null);
            dispatch(reloadRoomPlannerAction(true));
            setMessage({severity: "success", text: t("RoomPlanner.deleted")});
        }
    }, [previousStatus, status]);

    useEffect(() => {
        if (isRejected(status)) {
            setMessage({severity: "error", text: t("RoomPlanner.error")});
        }
    }, [previousStatus, status]);

    return <RoomPlannerDetailsDialog setPendingType={setPendingType} slots={slots} onAdd={onAdd} />;
};

RoomPlannerDetails.propTypes = {
    slots: PropTypes.arrayOf(PropTypes.object).isRequired, // see @typedef RoomBlockerSlot
    setMessage: PropTypes.func.isRequired,
    onAdd: PropTypes.func.isRequired
};

export default RoomPlannerDetails;
