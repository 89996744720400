// @ts-check
import {t} from "i18next";

import config from "../../../../config/config.json";
import {formatBirthDate} from "../../../utils/format_birth_date";
import buildFullName from "./build_fullname";

/**
 * build patient info (initial name (age) gender)
 * @param {PatientInfo} patientInfo
 * @param {String} id
 * @param {String} dateFormat
 * @return {{name: string, birthDate: string, gender: string}}
 */
const buildPatientInfo = (patientInfo, id, dateFormat) => {
    const {
        TEXT_PUNCTUATION: {HYPHEN}
    } = config;

    const name = buildFullName(patientInfo, id);
    const birthDate = formatBirthDate(patientInfo?.birthDate, dateFormat);
    const gender = patientInfo?.gender ? t(`GenderShort.${patientInfo.gender}`, patientInfo.gender) : HYPHEN;
    return {name, birthDate, gender};
};

export default buildPatientInfo;
