// @ts-check
import {endOf, getDateTimeFromISOWithTimezone, startOf} from "../../../utils/luxon_helpers";

const filterByDay =
    ({startOfTheDay, endOfTheDay, timezone}) =>
    ({start, end}) =>
        isOverlappingPeriod(
            {start, end},
            {
                start: startOfTheDay,
                end: endOfTheDay
            },
            timezone
        );

/**
 * is the period overlapping with another period?
 * @param {Object} period
 * @param {String} period.start     start of the period
 * @param {String} period.end       end of the period
 * @param {Object} compare
 * @param {DateTimeType} compare.start    start of the other period to compare with
 * @param {DateTimeType} compare.end      end of the other period to compare with
 * @param {String} timezone
 * @return {boolean}
 */
const isOverlappingPeriod = ({start, end}, {start: compareStart, end: compareEnd}, timezone) =>
    !isSameOrBefore(end, compareStart, timezone) && !isSameOrAfter(start, compareEnd, timezone);

/**
 * is date after compareDate or the same?
 * @param {String} timestamp
 * @param {DateTimeType} compare
 * @param {String} timezone
 * @return {boolean}
 */
const isSameOrAfter = (timestamp, compare, timezone) => getDateTimeFromISOWithTimezone(timestamp, timezone) >= compare;

/**
 * is date before compareDate or the same?
 * @param {String} timestamp
 * @param {DateTimeType} compare
 * @param {String} timezone
 * @return {boolean}
 */
const isSameOrBefore = (timestamp, compare, timezone) => getDateTimeFromISOWithTimezone(timestamp, timezone) <= compare;

/**
 * Changed blocking dates from daily intervall to complete intervall
 * @param {Array<{_id: String, start: String, end: String, reason: String}>} blockingDates
 * @param {DateTimeType} currentDate
 * @param {string} timezone
 * @return {Array<{_id: String, start: DateTimeType, end: DateTimeType, reason: String}>}
 */
const strechDates = (blockingDates, currentDate, timezone) => {
    const blockingDatesNew = [];
    for (const blockingDate of blockingDates) {
        const beginOfToday = startOf(currentDate, "day");
        const endOfToday = endOf(currentDate, "day");
        const startDateTime = getDateTimeFromISOWithTimezone(blockingDate.start, timezone);
        const endDateTime = getDateTimeFromISOWithTimezone(blockingDate.end, timezone);

        const adjustedStartDateTime = startDateTime < beginOfToday ? beginOfToday : startDateTime;
        const adjustedEndDateTime = endDateTime > endOfToday ? endOfToday : endDateTime;

        blockingDatesNew.push({
            _id: blockingDate._id,
            start: adjustedStartDateTime,
            end: adjustedEndDateTime,
            reason: blockingDate.reason
        });
    }

    return blockingDatesNew;
};

export {strechDates, isSameOrBefore, isSameOrAfter, filterByDay};
