import uniq from "lodash/uniq";
const practitioner = "Practitioner";
/**
 * get ids of patients and practitioners from operations (real, ki, customer, search)
 * @param {Array<ResourceHistoryChangeInfoLayer>} changeHistory
 * @return {Object} {practitionerIds, patientIds}
 */
const getPersonIdsFromChangeHistory = (changeHistory) => {
    const patientIds = [];
    const practitionerIds = [];

    for (const change of changeHistory) {
        patientIds.push(change.patientId);
        if (change.change.path.includes(practitioner)) {
            change.change.value && practitionerIds.push(change.change.value);
            change.change.valueBefore && practitionerIds.push(change.change.valueBefore);
        }
    }
    return {patientIds: uniq(patientIds), practitionerIds: uniq(practitionerIds)};
};

export default getPersonIdsFromChangeHistory;
