import {makeStyles} from "tss-react/mui";

export default makeStyles()((theme) => ({
    listWrapper: {
        margin: "1rem 0"
    },
    list: {
        listStyleType: "none",
        margin: 0,
        padding: 0
    },
    scrollableList: {
        maxHeight: "15rem",
        overflowY: "scroll"
    },
    item: {
        "fontSize": "0.75rem",
        "marginTop": theme.spacing("xxs"),

        "&:first-of-type": {
            marginTop: 0
        }
    },
    conflicts: {
        color: theme.palette.error.main
    },
    moreButton: {
        marginTop: theme.spacing("m"),
        padding: 0,
        fontSize: "0.75rem",
        textTransform: "none"
    }
}));
