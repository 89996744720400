/**
 * @fileoverview API for names of practitioners and patients
 */

import axiosClient from "../../middleware/axios.middelware";

const URL_PRIVATE = "/orchestrator/privateData";

/**
 * fetch names
 * @param {String} organizationId
 * @param {String} type - practitioner or patient
 * @param {Array} ids
 * @return {Promise}
 */
function fetchNamesAPI(organizationId, type, ids) {
    const data = {
        organizationId,
        ids
    };
    return axiosClient.post(`${URL_PRIVATE}/${type}`, data);
}

export {fetchNamesAPI};
