// @ts-check
import {authUserFailureAction} from "../../redux/actions/index";
import STATUS from "../../redux/utils/status";
import ActionTypes from "./rooms_action_types";
import {fetchRooms} from "./rooms_api";

const loadRoomsRequestAction = () => ({
    type: ActionTypes.ROOMS_REQUEST
});

const loadRoomsSuccessAction = (payload) => ({
    type: ActionTypes.ROOMS_SUCCESS,
    payload
});

const loadRoomsFailureAction = (error) => ({
    type: ActionTypes.ROOMS_FAILURE,
    error
});

/**
 * load rooms
 *
 * @param {string} organizationId
 * @return {AnyAction}
 */
function loadRoomsAction(organizationId) {
    return function (dispatch, getState) {
        // fetch rooms only once
        const state = getState();
        if (state.rooms.statusRooms !== STATUS.RESOLVED) {
            dispatch(loadRoomsRequestAction());

            fetchRooms(organizationId)
                .then(({data}) => {
                    dispatch(loadRoomsSuccessAction(data.data));
                })
                .catch((error) => {
                    if (error.response && error.response.status === 401) {
                        dispatch(authUserFailureAction({error: true, message: "fetch rooms error"}));
                    } else {
                        dispatch(loadRoomsFailureAction(error));
                    }
                });
        }
    };
}
export {loadRoomsAction};
