import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import PropTypes from "prop-types";
import React from "react";

import useStyles from "./room_select.style";

const RoomSelect = ({className, disabled, label, options, placeholder, value, onChange}) => {
    const {classes} = useStyles();

    const handleChange = ({target}) => onChange(target.value);

    return (
        <FormControl className={className} variant="standard">
            <InputLabel disabled={disabled} id="employee-select" shrink>
                {label}
            </InputLabel>
            <Select
                disabled={disabled}
                displayEmpty
                inputProps={{
                    className: classes.input
                }}
                labelId="employee-select"
                renderValue={(value) => {
                    if (!value) {
                        return placeholder;
                    }
                    return options.find((option) => option.id === value).name;
                }}
                value={value}
                variant="standard"
                onChange={handleChange}
            >
                {options.map((option) => (
                    <MenuItem className={classes.menuItem} key={option.id} value={option.id}>
                        {option.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

RoomSelect.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired
        })
    ),
    placeholder: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired
};

export default RoomSelect;
