/**
 * @fileoverview redux selectors for Rooms
 */
import {createSelector} from "reselect";

import sortLocation from "../../utils/sort_location";
import {selectThemeLocationInfos} from "../fe_settings/fe_settings_selectors";

const selectRooms = (state) => state.rooms;

/**
 * Information's room selector
 * @return {{name: string, id: string}[]} An array with the rooms information
 */
const selectRoomInfos = createSelector(selectRooms, selectThemeLocationInfos, (roomsStore, locationInfos) =>
    [...roomsStore.rooms].sort(sortLocation(locationInfos, "id")).map((room) => ({name: room.name, id: room.id}))
);
const selectStatusRooms = (state) => selectRooms(state).statusRooms;
const selectErrorRooms = (state) => selectRooms(state).errorRooms;

export {selectRooms, selectStatusRooms, selectErrorRooms, selectRoomInfos};
