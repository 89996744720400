/**
 * @fileoverview redux selectors for room planner
 * @return {object} object of selector functions
 */

const selectRoomPlannerState = (state) => state.roomPlanner;

const selectFromDate = (state) => selectRoomPlannerState(state).fromDate;
const selectToDate = (state) => selectRoomPlannerState(state).toDate;

const selectError = (state) => selectRoomPlannerState(state).error;

const selectStatus = (state) => selectRoomPlannerState(state).status;

const selectBlockedRooms = (state) => selectRoomPlannerState(state).blockedRooms;

export {selectBlockedRooms, selectError, selectStatus, selectFromDate, selectToDate};
