/**
 * @fileoverview API for room planner
 */

import axiosClient from "../../middleware/axios.middelware";

const URL = "/orchestrator/locations/or/availabilities";

/**
 * fetch all blocked rooms
 * @param {number} organizationId
 * @param {string} fromDate
 * @param {string} toDate
 * @return {Promise}
 */
function fetchAllBlockedRooms(organizationId, fromDate, toDate) {
    const params = {
        organizationId,
        fromDate,
        toDate
    };

    return axiosClient.get(URL, {params});
}

export {fetchAllBlockedRooms};
