/**
 * @fileoverview redux actions for rooms
 */

import {authUserFailureAction} from "../../redux/actions/index";
import ActionTypes from "./room_planner_details_action_types";
import {createRoomBlockingDate, deleteRoomBlockingDate, updateRoomBlockingDate, validateRoomBlocker} from "./room_planner_details_api";

const clearConflictsAction = () => ({
    type: ActionTypes.CLEAR_CONFLICTS
});

const createRoomBlockerRequestAction = () => ({
    type: ActionTypes.CREATE_REQUEST
});

const createRoomBlockerSuccessAction = (createdAt, newId) => ({
    type: ActionTypes.CREATE_SUCCESS,
    createdAt,
    newId
});

const createRoomBlockerFailureAction = (error) => ({
    type: ActionTypes.CREATE_FAILURE,
    error
});

/**
 * create a blocking date
 * @param {String} id           the internal room id
 * @param {Object} data         the data to insert
 * @param {String} organizationId
 * @param {String} createdAt
 * @return {function}
 */
function createRoomBlockerAction(id, data, organizationId, createdAt) {
    return function (dispatch, getState) {
        dispatch(createRoomBlockerRequestAction());
        createRoomBlockingDate(id, data, organizationId)
            .then((result) => {
                const newBlockerId = result.data?.data.length && result.data.data[0]._id;
                dispatch(createRoomBlockerSuccessAction(createdAt, newBlockerId));
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(authUserFailureAction({error: true, message: "create roomBlocker error"}));
                } else {
                    dispatch(createRoomBlockerFailureAction(error.message));
                }
            });
    };
}

const updateRoomBlockerRequestAction = () => ({
    type: ActionTypes.UPDATE_REQUEST
});

const updateRoomBlockerSuccessAction = (blockerId, patchedBlockerId) => ({
    type: ActionTypes.UPDATE_SUCCESS,
    blockerId,
    patchedBlockerId
});

const updateRoomBlockerFailureAction = (error) => ({
    type: ActionTypes.UPDATE_FAILURE,
    error
});

/**
 * update a blocking date
 * @param {String} id           the internal room id
 * @param {String} blockerId    the internal id of the blocking date entry
 * @param {Object} data         the data to change
 * @param {String} organizationId
 * @param {String} originalBlockerId
 * @return {function}
 */
function updateRoomBlockerAction(id, blockerId, data, organizationId, originalBlockerId) {
    return function (dispatch) {
        dispatch(updateRoomBlockerRequestAction());
        updateRoomBlockingDate(id, blockerId, data, organizationId)
            .then((result) => {
                const patchedBlocker = result.data.data?.length && result.data.data[0];
                dispatch(updateRoomBlockerSuccessAction(originalBlockerId, patchedBlocker?._id));
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(authUserFailureAction({error: true, message: "update roomBlocker error"}));
                } else {
                    dispatch(updateRoomBlockerFailureAction(error.message));
                }
            });
    };
}

const deleteRoomBlockerRequestAction = () => ({
    type: ActionTypes.DELETE_REQUEST
});

const deleteRoomBlockerSuccessAction = (payload, id) => ({
    type: ActionTypes.DELETE_SUCCESS,
    payload,
    id
});

const deleteRoomBlockerFailureAction = (error) => ({
    type: ActionTypes.DELETE_FAILURE,
    error
});

/**
 * delete a blocking date or a series from a room
 * @param {String} roomIdHash       the internal room id
 * @param {String} blockerId         the internal id of the blocking date entry
 * @param {String} organizationId   organizationId
 * @param {Boolean} deleteSeries    delete the series or the single element
 * @return {function}
 */
function deleteRoomBlockerAction(roomIdHash, blockerId, organizationId, deleteSeries = false) {
    return function (dispatch) {
        dispatch(deleteRoomBlockerRequestAction());

        deleteRoomBlockingDate(roomIdHash, blockerId, organizationId, deleteSeries)
            .then(({data}) => {
                dispatch(deleteRoomBlockerSuccessAction(data.data, blockerId));
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(authUserFailureAction({error: true, message: "delete roomBlocker error"}));
                } else {
                    dispatch(deleteRoomBlockerFailureAction(error.message));
                }
            });
    };
}

const validateRoomBlockerRequestAction = () => ({
    type: ActionTypes.VALIDATE_REQUEST
});

const validateRoomBlockerSuccessAction = (conflicts, id) => ({
    type: ActionTypes.VALIDATE_SUCCESS,
    conflicts,
    id
});

const validateRoomBlockerFailureAction = (conflicts = []) => ({
    type: ActionTypes.VALIDATE_FAILURE,
    conflicts
});

/**
 * validate a room blocker
 * @param {String} organizationId
 * @param {String} roomId
 * @param {Object} slot     existing slot
 * @param {Object} data
 * @return {function}
 */
function validateRoomBLockerAction(organizationId, roomId, slot, data) {
    return function (dispatch) {
        dispatch(validateRoomBlockerRequestAction());

        validateRoomBlocker(organizationId, roomId, slot, data)
            .then(({data}) => {
                // success? validation passed
                const id = slot.isNew ? slot.createdAt : slot.blockerId;
                dispatch(validateRoomBlockerSuccessAction(data.data.conflicts, id));
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(authUserFailureAction({error: true, message: "validate roomBlocker error"}));
                } else {
                    const {data} = error.response || {};
                    dispatch(validateRoomBlockerFailureAction(data.conflicts, data.preCalcDates));
                }
            });
    };
}

export {clearConflictsAction, createRoomBlockerAction, deleteRoomBlockerAction, updateRoomBlockerAction, validateRoomBLockerAction};
