import {createReducer} from "../../redux/utils/reducer";
import STATUS from "../../redux/utils/status";
import ActionTypes from "./private_data_action_types";

const initialState = {
    practitioner: {},
    patient: {},
    patientAll: {},
    statusPrivateData: STATUS.IDLE,
    errorPrivateData: null
};

const handleNamesRequest = (state) => ({
    ...state,
    statusPrivateData: STATUS.PENDING,
    errorPrivateData: null
});

const handleNamesFailure = (state, {error}) => ({
    ...state,
    statusPrivateData: STATUS.REJECTED,
    errorPrivateData: error
});

const handleNamesSuccess = (state, {payload, nameType, replace, isAll}) => {
    const property = isAll ? `${nameType}All` : nameType;
    const value = replace ? payload[nameType] : {...state[nameType], ...payload[nameType]};
    return {
        ...state,
        statusPrivateData: STATUS.RESOLVED,
        errorPrivateData: null,
        [property]: value
    };
};

const handleNamesClear = () => ({
    ...initialState
});

const handlers = {
    [ActionTypes.PRIVATE_DATA_REQUEST]: handleNamesRequest,
    [ActionTypes.PRIVATE_DATA_SUCCESS]: handleNamesSuccess,
    [ActionTypes.PRIVATE_DATA_FAILURE]: handleNamesFailure,
    [ActionTypes.PRIVATE_DATA_CLEAR]: handleNamesClear
};
export default createReducer(initialState, handlers);
