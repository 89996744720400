// @ts-check
/**
 * @fileoverview API for rooms
 */

import axiosClient from "../../middleware/axios.middelware";

const URL_ROOMS = "/orchestrator/locations/or";

/**
 * fetch rooms
 *
 * @param {string} organizationId
 * @return {Promise}
 */
function fetchRooms(organizationId) {
    const queryParams = {
        organizationId
    };
    return axiosClient.get(URL_ROOMS, {params: queryParams});
}

export {fetchRooms};
