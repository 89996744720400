// @ts-check
/**
 * @fileoverview redux selectors for room planner details
 */

import {createSelector} from "reselect";

const selectRoomPlannerDetailsState = (state) => state.roomPlannerDetails;

const selectError = (state) => selectRoomPlannerDetailsState(state).error;

const selectStatus = (state) => selectRoomPlannerDetailsState(state).status;

const selectConflicts = ({id}) =>
    createSelector(selectRoomPlannerDetailsState, (roomPlannerDetailsStateState) => roomPlannerDetailsStateState[id] || []);

const selectAdded = (state, {id}) => selectRoomPlannerDetailsState(state).added[id];

const selectDeleted = (state) => selectRoomPlannerDetailsState(state).deleted;

const selectPatched = (state, {id}) => selectRoomPlannerDetailsState(state).patched[id];

export {selectError, selectStatus, selectConflicts, selectAdded, selectDeleted, selectPatched};
