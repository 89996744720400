import PropTypes from "prop-types";
import React from "react";

import useStyles from "./table_cell_box.styles";

const TableCellContent = (props) => {
    const {classes} = useStyles();

    return (
        <div className={classes.root} data-testid="roomBox">
            <div className={classes.header} title={props.textHeader}>
                {props.textHeader}
            </div>
            <div className={classes.footer} title={props.textFooter}>
                {props.textFooter}
            </div>
        </div>
    );
};

TableCellContent.propTypes = {
    textHeader: PropTypes.string.isRequired,
    textFooter: PropTypes.string.isRequired
};

export default TableCellContent;
