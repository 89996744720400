import {makeStyles} from "tss-react/mui";
/** @typedef {import('@mui/material').Theme} Theme */

// /**
//  * @param {Theme} theme
//  */
export default makeStyles()((theme) => ({
    select: {
        width: "100%",
        marginBottom: "2rem"
    },
    divider: {
        ...theme.custom.rightPanel.divider
    },
    buttonsWrapper: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "80px",
        padding: "0 10px 0 10px"
    },
    reset: {
        // fontWeight: theme.typography.fontWeightLight,
        textTransform: "none"
    },
    button: {
        ...theme.custom.rightPanel.button,
        minWidth: "108px",
        width: "unset"
    },
    form: {
        padding: "2.5rem 2.5rem 0 2.5rem",
        height: "calc(100% - 80px)",
        display: "flex",
        flexDirection: "column"
    },
    pushToBottom: {
        marginTop: "auto"
    },
    error: {
        color: theme.palette.error.main,
        fontSize: "0.688rem",
        marginTop: theme.spacing("s")
    }
}));
