import {Button, Checkbox, FormControlLabel} from "@mui/material";
import PropTypes from "prop-types";
import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {selectCurrentOrganizationId} from "../../../../redux/app_selectors";
import {isResolved} from "../../../../redux/utils/status";
import sortLocation from "../../../../utils/sort_location";
import {selectThemeLocationInfos} from "../../../fe_settings/fe_settings_selectors";
import {loadRoomsAction} from "../../rooms_actions";
import {selectRoomInfos, selectStatusRooms} from "../../rooms_selectors";
import useStyles from "./rooms_filter.styles";
/**
 * Rooms
 * @return {JSX}
 */
export const RoomsFilter = ({opRooms, onSave}) => {
    const {classes, cx} = useStyles();
    const {t} = useTranslation();
    const inputRef = useRef(null);
    const dispatch = useDispatch();

    // Redux
    const organizationId = useSelector(selectCurrentOrganizationId);
    const roomInfos = useSelector(selectRoomInfos);
    const statusRooms = useSelector(selectStatusRooms);
    const locationInfos = useSelector(selectThemeLocationInfos);

    useEffect(() => {
        if (inputRef && inputRef.current) {
            inputRef.current.focus();
        }
    }, [inputRef]);

    const [opRoomsState, setOpRoomsState] = useState(opRooms);

    useEffect(() => {
        dispatch(loadRoomsAction(organizationId));
    }, []);

    if (!isResolved(statusRooms)) {
        return null;
    }

    const handleClickSave = () => {
        onSave({
            opRooms: opRoomsState
        });
    };

    const handleClickCancel = () => {
        setOpRoomsState(opRooms);
    };

    const handleToggle = (e) => {
        if (e.target.checked) {
            const newOpRooms = [...opRoomsState, e.target.value].sort(sortLocation(locationInfos));
            setOpRoomsState(newOpRooms);
        } else {
            const newOpRooms = [...opRoomsState];
            const index = newOpRooms.indexOf(e.target.value);
            newOpRooms.splice(index, 1);
            setOpRoomsState(newOpRooms);
        }
    };

    let isChanged = false;

    // Check if opRooms are changed
    for (const label of opRooms) {
        if (!opRoomsState.includes(label)) isChanged = true;
    }
    if (opRooms.length !== opRoomsState.length) isChanged = true;

    return (
        <div className={classes.root}>
            <div className={classes.contentWrapper}>
                <div className={classes.title}>{t("RoomsFilter.title")}</div>
                <div className={classes.divider} />
                <div className={classes.content}>
                    <div className={classes.subtitle}>{t("RoomsFilter.rooms")}</div>
                    {roomInfos.map((room, index) => {
                        const isSelected = opRoomsState.includes(room.id);
                        return (
                            <div key={room.id}>
                                <FormControlLabel
                                    classes={{label: classes.checkBoxLabel, root: classes.checkBoxRoot}}
                                    control={
                                        <Checkbox
                                            checked={isSelected}
                                            inputProps={{
                                                "data-testid": "room-checkbox-element"
                                            }}
                                            inputRef={index === 0 ? inputRef : null}
                                            name={room.name}
                                            value={room.id}
                                            onChange={handleToggle}
                                        />
                                    }
                                    data-testid="room-checkbox-label"
                                    label={locationInfos?.[room.id] ? `${locationInfos[room.id].label} (${room.name})` : room.name}
                                />
                            </div>
                        );
                    })}
                </div>
                <div className={classes.divider} />
                <div className={classes.buttonWrapper}>
                    <Button
                        className={cx(classes.button, classes.buttonWhite)}
                        color="primary"
                        variant="outlined"
                        onClick={handleClickCancel}
                    >
                        {t("RoomsFilter.cancel")}
                    </Button>
                    <Button className={classes.button} color="primary" disabled={!isChanged} variant="contained" onClick={handleClickSave}>
                        {t("RoomsFilter.save")}
                    </Button>
                </div>
            </div>
        </div>
    );
};

RoomsFilter.propTypes = {
    opRooms: PropTypes.arrayOf(PropTypes.string).isRequired,
    onSave: PropTypes.func.isRequired
};
export default RoomsFilter;
