/**
 * @fileoverview redux actions for the room planner
 */

import {authUserFailureAction} from "../../redux/actions/index";
import ActionTypes from "./room_planner_action_types";
import {fetchAllBlockedRooms} from "./room_planner_api";

const loadBlockedRoomsRequestAction = (fromDate, toDate) => ({
    type: ActionTypes.LOAD_REQUEST,
    fromDate,
    toDate
});

const loadBlockedRoomsSuccessAction = (payload) => ({
    type: ActionTypes.LOAD_SUCCESS,
    payload
});

const loadBlockedRoomsFailureAction = (error) => ({
    type: ActionTypes.LOAD_FAILURE,
    error
});

/**
 * load blocked rooms
 * @param {string} organizationId
 * @param {string} fromDate i.e. YYYY-MM-DD
 * @param {string} toDate i.e. YYYY-MM-DD
 * @return {function}
 */
function loadBlockedRoomsAction(organizationId, fromDate, toDate) {
    return function (dispatch) {
        dispatch(loadBlockedRoomsRequestAction(fromDate, toDate));

        fetchAllBlockedRooms(organizationId, fromDate, toDate)
            .then(({data}) => {
                dispatch(loadBlockedRoomsSuccessAction(data.data.locations));
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(authUserFailureAction({error: true, message: "fetch blockedRooms error"}));
                } else {
                    dispatch(loadBlockedRoomsFailureAction(error.message));
                }
            });
    };
}

export {loadBlockedRoomsAction};
