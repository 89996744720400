import {createReducer} from "../../redux/utils/reducer";
import STATUS from "../../redux/utils/status";
import ActionTypes from "./rooms_action_types";

const initialState = {
    rooms: [],
    statusRooms: STATUS.IDLE,
    errorRooms: null
};

const handleRoomsRequest = (state) => ({
    ...state,
    statusRooms: STATUS.PENDING,
    errorRooms: null
});

const handleRoomsFailure = (state, {error}) => ({
    ...state,
    statusRooms: STATUS.REJECTED,
    errorRooms: error
});

const handleRoomsSuccess = (state, {payload}) => ({
    ...state,
    statusRooms: STATUS.RESOLVED,
    errorRooms: null,
    rooms: payload.locations ? payload.locations : []
});

const handlers = {
    [ActionTypes.ROOMS_REQUEST]: handleRoomsRequest,
    [ActionTypes.ROOMS_SUCCESS]: handleRoomsSuccess,
    [ActionTypes.ROOMS_FAILURE]: handleRoomsFailure
};
export default createReducer(initialState, handlers);
