// @ts-check
import {Button} from "@mui/material";
import PropTypes from "prop-types";
import React, {useContext} from "react";
import {useTranslation} from "react-i18next";

import {DateContext} from "../../../contexts/dates";
import useStyles from "./room_planner_details_dialog.styles";
import RoomPlannerDetailsForm from "./room_planner_details_form";

/**
 * RoomPlannerDetailsDialog
 * @param {Object} props
 * @param {Array<AdjustedRoomBlockerSlot>} props.slots
 * @param {function} props.setPendingType
 * @param {function} props.onAdd
 * @return {React.ReactElement}
 */
const RoomPlannerDetailsDialog = ({slots, setPendingType, onAdd}) => {
    const {t} = useTranslation();
    const {classes} = useStyles();
    const {now} = useContext(DateContext);

    /**
     * handler to add a blocker
     */
    const handleAdd = () => {
        const date = slots.length ? slots[0].start : now();
        const roomIdHash = slots.length ? slots[0].roomIdHash : "";
        onAdd(date, roomIdHash, true);
    };
    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <div className={classes.title}>
                    <div className={classes.titleText}>{t("RoomPlanner.title")}</div>
                    <Button className={classes.button} color="primary" variant="contained" onClick={handleAdd}>
                        {t("RoomPlanner.add")}
                    </Button>
                </div>
                <div className={classes.headerDivider} />
                <div className={classes.formContainer}>
                    {slots &&
                        slots.map((slot, i) => (
                            <div key={slot.blockerId || "index-" + i}>
                                <RoomPlannerDetailsForm setPendingType={setPendingType} slot={slot} />
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};

RoomPlannerDetailsDialog.propTypes = {
    slots: PropTypes.arrayOf(PropTypes.object), // see @typedef RoomBlockerSlot
    setPendingType: PropTypes.func.isRequired,
    onAdd: PropTypes.func.isRequired
};

RoomPlannerDetailsDialog.defaultProps = {
    defaultValues: {}
};

export default RoomPlannerDetailsDialog;
