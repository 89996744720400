// @ts-check
import {t} from "i18next";
import {createSelector} from "reselect";

import {buildStandardName} from "./helpers";
import buildFirstAndLastName from "./utils/build_first_and_last_name";
import buildFullName from "./utils/build_fullname";

/**
 * @fileoverview redux selectors for Names
 */

const selectPrivateDataState = (state) => state.privateData;

const selectPractitionerInfos = (state) => selectPrivateDataState(state).practitioner;
const selectPractitionerIds = (state) => Object.keys(selectPrivateDataState(state).practitioner);
const selectPatientInfos = (state) => selectPrivateDataState(state).patient;
const selectPatientInfo = (state, {id}) => selectPrivateDataState(state).patient[id];
const selectPatientIds = (state) => Object.keys(selectPrivateDataState(state).patient);
const selectStatusPrivateData = (state) => selectPrivateDataState(state).statusPrivateData;
const selectErrorPrivateData = (state) => selectPrivateDataState(state).errorPrivateData;

const selectFullName = ({id, type}) =>
    createSelector(selectPrivateDataState, (privateDataState) => {
        // If id is not set, return null
        if (!id) return null;
        return buildFullName(privateDataState[type][id], id);
    });

const selectStandardName = ({id, type}) =>
    createSelector(selectPrivateDataState, (privateDataState) => {
        const nameObject = privateDataState[type][id];
        return nameObject ? buildStandardName(nameObject.family, nameObject.given, id) : t("App.unknown");
    });
const selectFullNames = (state, {ids, type}) => {
    const names = ids.map((id) => buildFullName(selectPrivateDataState(state)[type][id], id));
    return names.join("; ");
};
const selectFullNamesArray = ({ids, type}) =>
    createSelector(selectPrivateDataState, (privateDataState) =>
        ids.map((id) => ({id, name: buildFullName(privateDataState[type][id], id)}))
    );

const selectAllFullNamesArray = ({type}) =>
    createSelector([selectPrivateDataState], (privateDatta) => {
        const ids = Object.keys(privateDatta[type]);
        return ids.map((id) => ({id, name: buildFullName(privateDatta[type][id], id)}));
    });

const selectStandardNamesArray = ({type, ids}) =>
    createSelector(
        selectPrivateDataState,
        (privateDataState) =>
            ids.map((id) => {
                const nameObject = privateDataState[type][id];
                return {id: id, name: nameObject ? buildStandardName(nameObject.family, nameObject.given, id) : t("App.unknown")};
            }) || []
    );

const selectAllStandardNamesObject = ({type}) =>
    createSelector(selectPrivateDataState, (privateDataState) => {
        /** @type {IdToNameCollection} */
        const result = {};
        for (const id in privateDataState[type]) {
            const nameObject = privateDataState[type][id];
            result[id] = nameObject ? buildStandardName(nameObject.family, nameObject.given, id) : t("App.unknown");
        }
        return result;
    });

const selectAllFullNamesObject = ({type}) =>
    createSelector(selectPrivateDataState, (privateData) => {
        const result = {};
        for (const id in privateData[type]) {
            result[id] = buildFullName(privateData[type][id], id);
        }
        return result;
    });

const selectAllFirstAndLastNamesObject = ({type}) =>
    createSelector([selectPrivateDataState], (privateData) => {
        const result = {};
        for (const id in privateData[type] || {}) {
            result[id] = buildFirstAndLastName(privateData[type]?.[id], id);
        }
        return result;
    });

const selectPatientGender = (state, {id}) => selectPrivateDataState(state).patient[id]?.gender;

const selectPatientBirthDate = (state, {id}) => selectPrivateDataState(state).patient[id]?.birthDate;

const selectAllPatientBirthDates = createSelector([selectPrivateDataState], (privateData) => {
    const result = {};
    for (const id in privateData.patient) {
        result[id] = privateData.patient[id]?.birthDate;
    }
    return result;
});

export {
    selectPrivateDataState,
    selectPractitionerInfos,
    selectPractitionerIds,
    selectPatientInfos,
    selectPatientInfo,
    selectPatientIds,
    selectStatusPrivateData,
    selectErrorPrivateData,
    selectFullName,
    selectFullNames,
    selectFullNamesArray,
    selectAllFullNamesArray,
    selectStandardName,
    selectStandardNamesArray,
    selectAllStandardNamesObject,
    selectAllFullNamesObject,
    selectPatientGender,
    selectPatientBirthDate,
    selectAllPatientBirthDates,
    selectAllFirstAndLastNamesObject
};
