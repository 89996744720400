// @ts-check
import {AddCircleOutline} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import React, {useContext} from "react";

import {DATE_FORMATS, DateContext} from "../../../contexts/dates";
import {PERMISSION, useSecurity} from "../../../utils/security";
import PermissionTooltip from "../../shared/permission_tooltip/permission_tooltip";
import {strechDates} from "../utils/manipulateDates";
import TableCellBox from "./table_cell_box";
import useStyles from "./table_cell_content.styles";

const sortPeriods = (a, b) => {
    if (a.start < b.start) {
        return -1;
    }

    if (a.start > b.start) {
        return 1;
    }

    if (a.end < b.end) {
        return -1;
    }

    if (a.end > b.end) {
        return 1;
    }

    return 0;
};
/**
 * render TableCellContent component
 *
 * @param {Object} props
 * @param {Array<{_id: String, start: String, end: String, reason: String}>} props.blockingDates
 * @param {{id: String, name: String}} props.room
 * @param {Boolean} [props.isPending]
 * @param {String} props.timezone
 * @param {DateTimeType} props.currentDate
 * @param {Function} props.onAddClick
 * @param {Function} props.onEditClick
 * @param {Boolean} props.hovered
 * @return {React.ReactElement}
 */
const TableCellContent = ({blockingDates, room, isPending, timezone, currentDate, onAddClick, onEditClick, hovered}) => {
    const {classes, cx} = useStyles();
    const blockingDatesNew = strechDates(blockingDates, currentDate, timezone).sort(sortPeriods);
    const {isGranted} = useSecurity();
    const {format} = useContext(DateContext);

    const isAllowed = isGranted(PERMISSION.MODIFY_LOCATION);

    const handleEntryClick = (ids) =>
        isAllowed
            ? () => {
                  onEditClick(ids, room);
              }
            : null;

    if (!blockingDatesNew.length && !isPending) {
        return (
            <div
                className={cx(classes.add, {
                    [classes.cursor]: isAllowed
                })}
                role={"button"}
                tabIndex={0}
                onClick={() => onAddClick()}
                onKeyDown={(e) => e.key === "Enter" && onAddClick()}
            >
                <PermissionTooltip isAllowed={isAllowed}>
                    <IconButton className={classes.iconButton} disabled={!isAllowed} size="large">
                        <AddCircleOutline
                            className={
                                isAllowed
                                    ? cx(classes.addIcon, {
                                          [classes.addIconHovered]: hovered
                                      })
                                    : classes.disabled
                            }
                            data-testid="addRoomButtonCanvas"
                            fontSize="small"
                        />
                    </IconButton>
                </PermissionTooltip>
            </div>
        );
    }
    const textHeader = blockingDatesNew
        .map((period) => format(period.start, DATE_FORMATS.TIME) + " - " + format(period.end, DATE_FORMATS.TIME))
        .join(", ");
    const textFooter = blockingDatesNew.map((period) => period.reason).join(", ");
    const ids = blockingDatesNew.map((period) => period._id);
    return (
        <div
            className={cx({[classes.clickable]: isAllowed})}
            role={"button"}
            tabIndex={0}
            onClick={handleEntryClick(ids)}
            onKeyDown={(e) => e.key === "Enter" && handleEntryClick(ids)}
        >
            <TableCellBox textFooter={textFooter} textHeader={textHeader} />
        </div>
    );
};

TableCellContent.propTypes = {
    blockingDates: PropTypes.arrayOf(
        PropTypes.shape({
            _id: PropTypes.string.isRequired,
            start: PropTypes.string.isRequired, // ISO String
            end: PropTypes.string.isRequired // ISO String
        })
    ),
    room: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string
    }),
    isPending: PropTypes.bool,
    timezone: PropTypes.string.isRequired,
    currentDate: PropTypes.object.isRequired, // DateTime object
    onAddClick: PropTypes.func.isRequired,
    onEditClick: PropTypes.func.isRequired,
    hovered: PropTypes.bool
};

export default TableCellContent;
