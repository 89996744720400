import {PSEUDO} from "./constants";

/**
 * Build the last name (ex. Mustermann)
 * @param {{given: Array, family: string}} nameObject
 * @param {String} id - practitionerId or patientId
 * @return {String}
 */
const buildLastName = (nameObject, id) => {
    if (!id) return null;
    // Set family name
    return nameObject && nameObject.family && nameObject.family !== PSEUDO ? nameObject.family : id;
};

export default buildLastName;
