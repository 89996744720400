import {makeStyles} from "tss-react/mui";

export default makeStyles()((theme) => ({
    add: {
        display: "flex",
        justifyContent: "flex-end"
    },
    iconButton: {
        padding: "8px"
    },
    addIcon: {
        color: theme.palette.border,
        fontSize: "1.2rem"
    },
    addIconHovered: {
        color: theme.custom.emergency.main
    },
    disabled: {
        color: theme.palette.text.disabled
    },
    clickable: {
        cursor: "pointer"
    },
    cursor: {
        cursor: "pointer"
    }
}));
