import {makeStyles} from "tss-react/mui";

export default makeStyles()((theme) => ({
    root: {
        flexDirection: "column",
        margin: 0,
        padding: 0,
        borderRadius: "2px",
        backgroundColor: theme.custom.emergency.light,
        color: theme.custom.emergency.main
    },
    header: {
        padding: `${theme.spacing("xs")} ${theme.spacing("s")} 0`,
        fontSize: "0.875rem",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        display: "block",
        fontWeight: theme.typography.fontWeightBold
    },
    footer: {
        fontWeight: "300",
        padding: `0 ${theme.spacing("s")} ${theme.spacing("xs")}`,
        fontSize: "12px",
        fontStyle: "italic",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "block",
        whiteSpace: "nowrap"
    },
    headerRight: {
        width: "100%",
        fontSize: "0.6888rem",
        fontWeight: "500",
        lineHeight: "1.33",
        color: theme.palette.error.main,
        padding: "2px 2px 2px 8px",
        borderBottom: "1px dotted " + theme.palette.grey[500],
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    }
}));
