import {t} from "i18next";

import {PSEUDO} from "./constants";

/**
 * Build the first (given) and last (family) name (ex. Mustermann, Max)
 * @param {{given: Array, family: string}} nameObject
 * @param {String} id - practitionerId or patientId
 * @return {{given: Array<string>, family: string}}
 */
const buildFirstAndLastName = (nameObject, id) => {
    if (!id) return null;
    // return id if family or given[0] was set as Pseudo
    const isPseudo = nameObject?.given?.[0] === PSEUDO || nameObject?.family === PSEUDO;
    const isDev = ["local", "remote", "dev", "qa"].includes(global.NODE_ENV);
    if (isPseudo && isDev) {
        return {given: [id], family: id};
    }
    if (isPseudo && !isDev) {
        return {given: [t("App.unknown")], family: t("App.unknown")};
    }
    return {given: nameObject?.given || [], family: nameObject?.family};
};

export default buildFirstAndLastName;
