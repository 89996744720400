import {t} from "i18next";

import buildFirstName from "./build_firstname";
import buildLastName from "./build_lastname";
import {PSEUDO} from "./constants";

/**
 * Build the fullname (ex. Mustermann, Max)
 * @param {{given: Array, family: string}} nameObject
 * @param {String} id - practitionerId or patientId
 * @return {String}
 */
const buildFullName = (nameObject, id) => {
    if (!id) return null;
    if (!nameObject) return t("App.unknown");

    // return family name if given names are missing
    if ((!nameObject.given || !nameObject.given.length) && nameObject.family) return buildLastName(nameObject, id);

    // return first given name if family is missing
    if (!nameObject.family && nameObject.given && nameObject.given.length) return buildFirstName(nameObject, id);

    // return id if family or given[0] was set as Pseudo
    const isPseudo = nameObject.given[0] === PSEUDO || nameObject.family === PSEUDO;
    const isDev = ["local", "remote", "dev", "qa"].includes(global.NODE_ENV);
    if (isPseudo && isDev) {
        return id;
    }
    if (isPseudo && !isDev) {
        return t("App.unknown");
    }
    return [buildLastName(nameObject, id), buildFirstName(nameObject, id)].join(", ");
};

export default buildFullName;
