// @ts-check

/**
 * @description mapping for the category in the frontend (key) and categories from the backend (value)
 * This is used:
 *      - op box in the dayview: team is categoried to four groups separated with double slash (//)
 *      - op backlog: Chirurgen, Anäst, Anäst-Pflege, OP-Pflege
 */

/**
 * get ids of practitioners from op by category (surgeon, anesthesia, anesthesiaNurse, surgeryNurse)
 * @param {NextOrOpTeam} team
 * @param {ParticipantCategoriesSettings} mapping for feCategory and beCategory (=participantType)
 * @param {Array<string>} [excludeTypes=[]]
 * @return {OpTeam} ex. {surgeons: ["id1", "id2"], anesthesias: ["id3"]}
 */
const getPractitionerIdsFromScheduleOpByCategory = (team, mapping, excludeTypes = []) => {
    const ids = {};

    if (team && mapping) {
        Object.entries(mapping).forEach(([feCategory, beCategories]) => {
            // If the property does not exist
            if (!ids[feCategory]) {
                ids[feCategory] = [];
            }
            // properties are keys in the ids
            beCategories.forEach((beCategory) => {
                // categories are surgeon, anesthesia etc
                if (team?.[beCategory]) {
                    Object.entries(team?.[beCategory]).forEach(([practitionerType, practitionerId]) => {
                        const isExcludeType = excludeTypes.length ? excludeTypes.includes(practitionerType) : false;
                        if (!isExcludeType && practitionerId) {
                            ids[feCategory].push(practitionerId);
                        }
                    });
                }
            });
        });
    }

    return ids;
};

export default getPractitionerIdsFromScheduleOpByCategory;
