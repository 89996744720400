import {PSEUDO} from "./constants";

/**
 * Build the first name (ex. Max)
 * For multiple first names, first one is fully shown and the rest will be initials
 * @example Maria Theresia Rosaria will be Maria T. R.
 *
 * @param {{given: Array, family: string}} nameObject
 * @param {Stirng} id - practitionerId or patientId
 * @return {String}
 */
const buildFirstName = (nameObject, id) => {
    if (!id) return null;
    // Build the rest of first names
    const restFirstNames =
        nameObject?.given?.length >= 2
            ? nameObject?.given
                  .slice(1)
                  .filter(Boolean)
                  .map((name) => `${name.charAt(0).toUpperCase()}.`)
            : [];
    const restFirstNamesFormatted = restFirstNames.length ? " " + restFirstNames.join(" ") : "";

    return nameObject?.given?.length && nameObject.given[0] !== PSEUDO ? nameObject.given[0] + restFirstNamesFormatted : id;
};

export default buildFirstName;
