// @ts-check
/**
 * get discipline from opData
 * @param {AppointmentChanged} criticalDifferences
 * @param {"participants"|"personId"} type the name of property of ids
 * @return {string[]}
 */
const getIdsFromCriticalDifferences = (criticalDifferences, type) => {
    let ids = [];
    ["missing", "moved", "new"].forEach((diffType) => {
        if (criticalDifferences?.[diffType]?.length) {
            if (type === "personId") {
                ids.push(...criticalDifferences[diffType].map((el) => el[type]));
            }
            if (type === "participants") {
                criticalDifferences[diffType]
                    .map((el) => el.participants)
                    .filter(Boolean)
                    .forEach((participants) => {
                        Object.entries(participants).forEach(([_, pracTypeId]) => {
                            Object.entries(pracTypeId).forEach(([type, id]) => {
                                ids.push(id);
                            });
                        });
                    });
                ids.push(...criticalDifferences[diffType].map((el) => el[type]));
            }
        }
    });

    ids = [...new Set(ids)];
    // @ts-ignore
    return ids;
};

export default getIdsFromCriticalDifferences;
