/**
 * @typedef after
 * @type {object}
 * @property {string} surgeon1
 * @property {string} personId
 */

/**
 * @typedef DataSlot
 * @type {object}
 * @property {after} after
 */

/**
 * @typedef session
 * @type {object}
 * @property {DataSlot[]} openManualChanges
 * @property {DataSlot[]} completeManualChanges
 */

/**
 * @typedef status
 * @type {object}
 * @property {session} session
 */

/**
 * get ids of patients and practitioners from operations (real, ki, customer, search)
 * @param {status} status
 * @return {Object} {practitionerIds, patientIds}
 */
const getPersonIdsFromManualChanges = (status) => {
    let practitionerIds = [];
    let patientIds = [];

    // Check open manual changes
    if (status?.session?.openManualChanges) {
        for (const change of status?.session?.openManualChanges || []) {
            if (change?.after?.personId) {
                patientIds.push(change.after.personId);
            }
            if (change?.after?.surgeon1) {
                practitionerIds.push(change.after.surgeon1);
            }
        }
    }

    // Check completed manual changes
    if (status?.session?.completeManualChanges) {
        for (const change of status.session.completeManualChanges) {
            if (change?.after?.personId) {
                patientIds.push(change.after.personId);
            }
            if (change?.after?.surgeon1) {
                practitionerIds.push(change.after.surgeon1);
            }
        }
    }

    practitionerIds = [...new Set(practitionerIds)];
    patientIds = [...new Set(patientIds)];

    return {practitionerIds, patientIds};
};

export default getPersonIdsFromManualChanges;
