// @ts-check
import {Button} from "@mui/material";
import {array, bool, func, object} from "prop-types";
import React from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {ControlledSelectorMultiple, ControlledSelectorSingle} from "../../../../shared/controlled_form";
import useStyles from "./rooms_filter_advanced_from.styles";

/**
 * The form for the RoomsFilterAdvanced component
 *
 * @param {object} props The props
 * @param {Array<{label: string, value: string}>} props.roomsMenuList A list with the rooms, and with the extra options for "Show empty ORs" and "All scheduled ORs"
 * @param {RoomsFilterAdvancedFormData} props.defaultValues The form default default values
 * @param {Array<{label: string, value: string}>} props.planList The list of plan settings options
 * @param {Array<{label: string, value: string}>} props.disciplines The list of disciplines
 * @param {Function} props.handleReset The handler to reset the form
 * @param {Function} props.handleSave The handler to save the form
 * @param {Boolean} props.isPlanSettingRequired
 * @param {Boolean} props.hasNoFilterResultDayView Wether there are results when filtering in DayView
 * @param {Boolean} props.hasNoFilterResultOpManagement Wether there are results when filtering in OpManagement
 * @return {React.ReactElement} The form
 */

export const RoomsFilterAdvancedForm = ({
    roomsMenuList,
    defaultValues,
    planList,
    disciplines,
    isPlanSettingRequired,
    handleSave,
    handleReset,
    hasNoFilterResultDayView,
    hasNoFilterResultOpManagement
}) => {
    const {t} = useTranslation();
    const {classes, cx} = useStyles();

    const {control, reset, getValues, handleSubmit, setValue} = useForm({defaultValues});
    const hasErrorMessageInOpManagement = isPlanSettingRequired && hasNoFilterResultOpManagement;
    const hasErrorMessageInDayView = !isPlanSettingRequired && hasNoFilterResultDayView;

    /**
     * Send the submitted data to the handler to be saved on the redux store
     *
     * @param {RoomsFilterAdvancedFormData} data
     * @return {void}
     */
    const onSubmit = (data) => {
        handleSave(data);
    };

    /**
     * Reset all the form current filters in the internal form state and in the redux store
     *
     * @return {void}
     */
    const onReset = () => {
        reset({disciplinesFilter: [], roomsFilter: [], planSetting: "both"});
        handleReset();
    };

    /**
     * Handles the logic when "All scheduled ORs" or "ShowEmpty ORs" are selected.
     * We need this extra function because  "All scheduled ORs" and "ShowEmpty ORs"
     * have to behave as exclusive values in a Material UI Multiple Select.
     * This functions rely on the fact that the last clicked checkbox is the last
     * element on the value array.
     *
     * @param {Array<String>} value - The list of selected values
     * @return {void}
     */
    const handleCheckboxForAllOrEmptyRooms = (value) => {
        const isPlannedOpsSelectedLast = value[value.length - 1] === "plannedOps";
        const isPlannedOpsChecked = value.some((v) => v === "plannedOps");

        const isEmptyRoomsSelectedLast = value[value.length - 1] === "emptyRooms";
        const isEmptyRoomsChecked = value.some((v) => v === "emptyRooms");

        if (isPlannedOpsSelectedLast) {
            setValue("roomsFilter", ["plannedOps"]);
        } else if (isEmptyRoomsSelectedLast) {
            setValue("roomsFilter", ["emptyRooms"]);
        } else if (isPlannedOpsChecked) {
            const valuesWithoutPlanned = value.filter((v) => v !== "plannedOps");
            setValue("roomsFilter", valuesWithoutPlanned);
        } else if (isEmptyRoomsChecked) {
            const valuesWithoutEmpty = value.filter((v) => v !== "emptyRooms");
            setValue("roomsFilter", valuesWithoutEmpty);
        }
    };

    return (
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <div>
                <ControlledSelectorMultiple
                    classesStyle={classes.select}
                    control={control}
                    dataTestid="roomSelector"
                    extraHandler={handleCheckboxForAllOrEmptyRooms}
                    getValues={getValues}
                    items={roomsMenuList}
                    name="roomsFilter"
                    placeholder={t("RoomsFilterAdvanced.placeholderRooms")}
                    reset={reset}
                    title={t("RoomsFilterAdvanced.room")}
                />
                <ControlledSelectorMultiple
                    classesStyle={classes.select}
                    control={control}
                    getValues={getValues}
                    items={disciplines}
                    name="disciplinesFilter"
                    placeholder={t("App.disciplinesPlaceholder")}
                    reset={reset}
                    title={t("App.disciplinesLabel")}
                />
                {isPlanSettingRequired && (
                    <ControlledSelectorSingle
                        classesStyle={classes.select}
                        control={control}
                        items={planList}
                        name="planSetting"
                        title={t("RoomsFilterAdvanced.planDisplay")}
                    />
                )}
                {hasErrorMessageInOpManagement && <div>{t("RoomsFilterAdvanced.notFound")}</div>}
                {hasErrorMessageInDayView && <div>{t("RoomsFilterAdvanced.notFound")}</div>}
            </div>
            <>
                <div className={cx(classes.divider, classes.pushToBottom)} />
                <div className={classes.buttonsWrapper}>
                    <Button className={cx(classes.button)} variant="outlined" onClick={onReset}>
                        {t("RoomsFilterAdvanced.default")}
                    </Button>
                    <Button className={classes.button} data-testid="saveButton" type="submit" variant="contained">
                        {t("RoomsFilterAdvanced.save")}
                    </Button>
                </div>
            </>
        </form>
    );
};

RoomsFilterAdvancedForm.propTypes = {
    roomsMenuList: array.isRequired,
    defaultValues: object,
    planList: array.isRequired,
    disciplines: array.isRequired,
    isPlanSettingRequired: bool,
    handleSave: func.isRequired,
    handleReset: func.isRequired,
    hasNoFilterResultDayView: bool.isRequired,
    hasNoFilterResultOpManagement: bool.isRequired
};
