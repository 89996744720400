import {makeStyles} from "tss-react/mui";

/** @type {Object} */
export default makeStyles()((theme) => ({
    root: {
        width: "100%",
        display: "flex",
        flexDirection: "column"
    },
    content: {
        flex: 1
    },
    title: {
        ...theme.custom.rightPanel.title
    },
    divider: {
        ...theme.custom.rightPanel.divider
    },
    icon: {
        top: "0.4rem",
        position: "relative",
        marginRight: theme.spacing("xs")
    },
    buttonsWrapper: {
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        height: "80px",
        padding: "0 10px 0 10px"
    },
    button: {
        ...theme.custom.rightPanel.button
    },
    buttonWhite: {
        backgroundColor: theme.palette.primary.contrastText
    },
    mb05: {
        marginBottom: theme.spacing("l")
    },
    formWrapper: {
        display: "flex",
        flexDirection: "column",
        padding: "2.5rem 2.5rem 0 2.5rem"
    },
    placeholder: {
        color: theme.palette.grey[500],
        fontSize: "0.875rem"
    },
    input: {
        fontSize: "0.875rem",
        color: theme.palette.text.primary
    },
    menu: {
        fontSize: "0.875rem",
        minHeight: "auto"
    },
    error: {
        color: theme.palette.error.main,
        fontSize: "0.688rem",
        marginTop: theme.spacing("s"),
        paddingLeft: "2.5rem"
    },
    reset: {
        fontWeight: theme.typography.fontWeightLight,
        textTransform: "none"
    },
    info: {
        color: theme.palette.grey[700],
        fontSize: "0.688rem",
        marginTop: theme.spacing("s")
    }
}));
