/**
 * @fileoverview redux action types for room planner
 */
const ActionTypes = {
    RELOAD_ROOM_PLANNER: "room_planner/RELOAD_ROOM_PLANNER",

    LOAD_REQUEST: "room_planner/LOAD_ROOM_PLANNER_REQUEST",
    LOAD_SUCCESS: "room_planner/LOAD_ROOM_PLANNER_SUCCESS",
    LOAD_FAILURE: "room_planner/LOAD_ROOM_PLANNER_FAILURE"
};

export default ActionTypes;
