// @ts-check
import getPractitionerIdsFromScheduleOpByCategory from "./get_practitioner_ids_from_schedule_ops_by_category";

/**
 * get discipline from opData
 * @param {PlanBox} data
 * @param {ParticipantCategoriesSettings} mapping
 * @return {string[]}
 */
const getIdsFromPopup = (data, mapping) => {
    const team = getPractitionerIdsFromScheduleOpByCategory(data._team, mapping);
    // @todo this part will be adjusted and improved later in the story #12555
    // jumpers, assistants
    const jumperIds = Object.values(data._team?.jumper || {}).filter(Boolean);
    const assistantIds = Object.values(data._team?.assistant || {}).filter(Boolean);

    const ids = [].concat(...Object.values(team), jumperIds, assistantIds);
    return [...new Set(ids)];
};

export default getIdsFromPopup;
