import {makeStyles} from "tss-react/mui";

export default makeStyles()((theme) => ({
    root: {
        color: theme.palette.grey[900],
        width: "100%",
        position: "relative"
    },
    contentWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        height: "100%",
        position: "relative"
    },
    title: {
        ...theme.custom.rightPanel.title
    },
    subtitle: {
        fontWeight: theme.typography.fontWeightBold,
        marginBottom: theme.spacing("m")
    },
    divider: {
        ...theme.custom.rightPanel.divider
    },
    content: {
        padding: "2.5rem",
        overflow: "auto",
        flex: "1"
    },
    button: {
        ...theme.custom.rightPanel.button
    },
    buttonWrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        height: theme.custom.rightPanel.title.height,
        padding: "0 2.5rem 0 2.5rem"
    },
    buttonWhite: {
        backgroundColor: theme.palette.primary.contrastText
    },
    checkBoxLabel: {
        fontSize: "0.875rem",
        color: theme.palette.grey[700],
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    },
    checkBoxRoot: {
        "margin": "0.5rem 0",
        "height": "1.5rem",
        "& svg": {
            fontSize: "1.5rem"
        }
    }
}));
