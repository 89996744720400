import {makeStyles} from "tss-react/mui";

export default makeStyles()((theme) => ({
    input: {
        minWidth: "9rem",
        fontSize: "0.875rem",
        color: theme.palette.text.primary,
        padding: `${theme.spacing("s")} 0`
    },
    menuItem: {
        fontSize: "0.875rem"
    }
}));
