import {makeStyles} from "tss-react/mui";

/** @type {Object} */
export default makeStyles()((theme) => ({
    root: {
        height: "100%",
        overflow: "auto"
    },
    openRightLayer: {
        width: "calc(100% + 500px)"
    },
    tableWrapper: {
        overflow: "auto",
        tableLayout: "fixed"
    },
    headerCell: {
        "color": theme.palette.text.disabled,
        "fontSize": "0.75rem",
        "fontWeight": "normal",
        "backgroundColor": `${theme.custom.background.layer} !important`,
        "padding": `${theme.spacing("xs")} ${theme.spacing("s")}`,
        "textTransform": "uppercase",
        "borderLeft": `0.5px solid ${theme.palette.border}`,
        "borderBottom": `0.5px solid ${theme.palette.border}`,
        "&:first-of-type": {
            minWidth: "10rem !important",
            padding: `${theme.spacing("xs")} ${theme.spacing("m")}`
        }
    },
    width16: {
        width: "16%"
    },
    width12: {
        width: "12%"
    },
    bodyCell: {
        "borderLeft": `1px solid ${theme.palette.border}`,
        "borderBottom": `1px solid ${theme.palette.border}`,
        "borderTop": "3px solid transparent",
        "borderRight": "3px solid transparent",
        "backgroundColor": "#fff !important",
        "fontSize": "0.75rem !important",
        "padding": "2px 4px 4px 6px",
        "minWidth": "7rem",
        "height": "2.5rem",
        "color": theme.palette.grey[700],
        "textTransform": "uppercase",
        "&:first-of-type": {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            padding: `${theme.spacing("xs")} ${theme.spacing("m")}`
        }
    },
    roomName: {
        fontSize: "0.75rem",
        color: theme.palette.text.disabled,
        fontWeight: theme.typography.fontWeightMedium
    },
    hoverEffect: {
        "&:hover": {
            border: `2px solid ${theme.custom.emergency.main}`
        }
    },
    date: {
        margin: theme.spacing("xxs"),
        fontSize: "12px"
    },
    today: {
        borderRadius: "9px",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        padding: theme.spacing("xxs")
    },
    weekday: {
        margin: theme.spacing("xxs"),
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: theme.spacing("m"),
        paddingRight: theme.spacing("s")
    },
    active: {
        "& td": {
            borderTop: `2px solid ${theme.palette.primary.main}`,
            borderBottom: `2px solid ${theme.palette.primary.main}`
        },
        "& td:first-of-type": {
            borderLeft: `2px solid ${theme.palette.primary.main}`
        },
        "& td:last-of-type": {
            borderRight: `2px solid ${theme.palette.primary.main}`
        }
    }
}));
