// @ts-check
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";

import {DATE_FORMATS, DateContext} from "../../../contexts/dates";
import useStyles from "./conflict_list.styles";

/**
 *
 * @param {Object} props
 * @param {Number} [props.limit]
 * @param {Array<{start: String, end: String}>} props.list
 * @return {React.ReactElement}
 */
const ConflictList = ({limit = 5, list}) => {
    const {classes, cx} = useStyles();
    const {t} = useTranslation();
    const {formatFromISO} = useContext(DateContext);

    // state
    const [allVisible, setAllVisible] = useState(false);

    const handleShowAll = () => setAllVisible(true);

    const maxLength = allVisible ? list.length : limit;

    return (
        <div className={classes.listWrapper}>
            <ul className={cx(classes.list, {[classes.scrollableList]: allVisible})}>
                {list.slice(0, maxLength).map(({start, end}) => (
                    <li className={classes.item} key={`${start}-${end}`}>
                        <span
                            className={classes.conflicts}
                            title={`${formatFromISO(start, DATE_FORMATS.TIME)} - ${formatFromISO(end, DATE_FORMATS.TIME)}`}
                        >
                            {t("RoomPlanner.roomBlocked")} {formatFromISO(start, DATE_FORMATS.DATE)}
                        </span>
                    </li>
                ))}
            </ul>
            {list.length > 5 && !allVisible && (
                <Button className={classes.moreButton} color="primary" endIcon={<ExpandMoreIcon />} size="small" onClick={handleShowAll}>
                    {t("ConflictList.showAll")}
                </Button>
            )}
        </div>
    );
};

ConflictList.propTypes = {
    limit: PropTypes.number,
    list: PropTypes.arrayOf(
        PropTypes.shape({
            start: PropTypes.string.isRequired,
            end: PropTypes.string.isRequired,
            reason: PropTypes.string
        })
    ).isRequired
};

ConflictList.defaultProps = {
    limit: 5
};

export default ConflictList;
