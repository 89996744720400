/**
 * @fileoverview redux reducer for room planner
 */

import {createReducer} from "../../redux/utils/reducer";
import STATUS from "../../redux/utils/status";
import ActionTypes from "./room_planner_action_types";

const initialState = {
    fromDate: null,
    toDate: null,
    blockedRooms: [],
    status: STATUS.IDLE,
    error: null
};

const handleLoadRequest = (state, {fromDate, toDate}) => ({
    ...state,
    status: STATUS.PENDING,
    error: null,
    fromDate,
    toDate
});

const handleLoadFailure = (state, {error}) => ({
    ...state,
    status: STATUS.REJECTED,
    error
});

const handleLoadSuccess = (state, {payload}) => ({
    ...state,
    status: STATUS.RESOLVED,
    error: null,
    blockedRooms: payload
});

const handlers = {
    [ActionTypes.LOAD_REQUEST]: handleLoadRequest,
    [ActionTypes.LOAD_SUCCESS]: handleLoadSuccess,
    [ActionTypes.LOAD_FAILURE]: handleLoadFailure
};

export default createReducer(initialState, handlers);
