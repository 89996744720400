// @ts-check
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import PropTypes from "prop-types";
import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import {DATE_FORMATS, DateContext} from "../../../contexts/dates";
import {selectCurrentTimezone} from "../../../redux/app_selectors";
import {getDatesInPeriod} from "../../../utils/date_range";
import {filterByDay} from "../utils/manipulateDates";
import TableCellContent from "./table_cell_content";
import useStyles from "./week_table.styles";

const WEEKDAY_SATURDAY = 6;
const WEEKDAY_SUNDAY = 7;

/**
 * render WeekTable component
 *
 * @param {Object} props
 * @param {Object} props.data
 * @param {DateTimeType} props.to
 * @param {DateTimeType} props.from
 * @param {Boolean} props.showWeekend
 * @param {Function} props.onAdd
 * @param {Function} props.onEdit
 * @param {Boolean} props.openRightLayer
 * @return {React.ReactElement}
 */
const WeekTable = ({data, from, showWeekend, to, onAdd, onEdit, openRightLayer}) => {
    const {classes, cx} = useStyles();
    const {t} = useTranslation();
    const {getDT, format, areSame, now, startOf, endOf} = useContext(DateContext);

    const timezone = useSelector(selectCurrentTimezone);

    const dateRange = getDatesInPeriod(from, to).filter(
        (date) => showWeekend || (getDT(date, "weekday") !== WEEKDAY_SATURDAY && getDT(date, "weekday") !== WEEKDAY_SUNDAY)
    );

    /** @type [{id?: String, date?: DateTimeType}, Function] */
    const [hovered, setHovered] = useState({});

    /**
     * handler for mouse over, set hoveredOp in redux
     * @param {String} id
     * @param {DateTimeType} date
     */
    const handleMouseOver = (id, date) => {
        setHovered({id, date});
    };

    /**
     * handler for mouse leave, reset hoveredOp in redux
     */
    const handleMouseLeave = () => {
        setHovered({});
    };
    return (
        <TableContainer className={classes.root}>
            <Table
                className={cx(classes.tableWrapper, {
                    [classes.openRightLayer]: openRightLayer
                })}
                size="small"
                stickyHeader
            >
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.headerCell}>{t("RoomPlannerPage.room")}</TableCell>
                        {dateRange.map((date) => (
                            <TableCell
                                className={cx(classes.headerCell, {
                                    [classes.width16]: !showWeekend,
                                    [classes.width12]: showWeekend
                                })}
                                key={format(date, DATE_FORMATS.ISO_DATE)}
                            >
                                <span className={classes.weekday}>{format(date, DATE_FORMATS.WEEK_NAME)}</span>
                                <span
                                    className={cx(classes.date, {
                                        [classes.today]: areSame(date, now(), "day")
                                    })}
                                >
                                    {format(date, DATE_FORMATS.DATE_SHORT)}
                                </span>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row) => (
                        <TableRow key={row._id}>
                            <TableCell className={cx(classes.bodyCell, classes.roomName)} title={row.name}>
                                {row.name}
                            </TableCell>
                            {dateRange.map((date) => (
                                <TableCell
                                    className={cx(classes.bodyCell, classes.hoverEffect)}
                                    key={format(date, DATE_FORMATS.ISO_DATE)}
                                    onMouseEnter={() => handleMouseOver(row._id, date)}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    <TableCellContent
                                        blockingDates={row.blockingDates.filter(
                                            filterByDay({
                                                startOfTheDay: startOf(date, "day"),
                                                endOfTheDay: endOf(date, "day"),
                                                timezone
                                            })
                                        )}
                                        currentDate={date}
                                        hovered={hovered?.id === row._id && areSame(hovered.date, date, "day")}
                                        room={{id: row._id, name: row.name}}
                                        timezone={timezone}
                                        onAddClick={() => onAdd(date, row._id)} // @todo #14813: onEditClick={onEdit} has a type-check error
                                        onEditClick={onEdit}
                                    />
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

WeekTable.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            _id: PropTypes.string.isRequired
        })
    ).isRequired,
    from: PropTypes.object.isRequired, // DateTime object
    showWeekend: PropTypes.bool,
    to: PropTypes.object.isRequired, // DateTime object
    onAdd: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    openRightLayer: PropTypes.bool
};

export default WeekTable;
