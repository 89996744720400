/**
 * @fileoverview redux action types for room availability
 */

const ActionTypes = {
    CLEAR_CONFLICTS: "room_planner/CLEAR_CONFLICTS",

    CREATE_REQUEST: "room_planner/CREATE_REQUEST",
    CREATE_SUCCESS: "room_planner/CREATE_SUCCESS",
    CREATE_FAILURE: "room_planner/CREATE_FAILURE",

    UPDATE_REQUEST: "room_planner/UPDATE_REQUEST",
    UPDATE_SUCCESS: "room_planner/UPDATE_SUCCESS",
    UPDATE_FAILURE: "room_planner/UPDATE_FAILURE",

    DELETE_REQUEST: "room_planner/DELETE_REQUEST",
    DELETE_SUCCESS: "room_planner/DELETE_SUCCESS",
    DELETE_FAILURE: "room_planner/DELETE_FAILURE",

    VALIDATE_REQUEST: "room_planner/VALIDATE_ROOM_BLOCKER_REQUEST",
    VALIDATE_SUCCESS: "room_planner/VALIDATE_ROOM_BLOCKER_SUCCESS",
    VALIDATE_FAILURE: "room_planner/VALIDATE_ROOM_BLOCKER_FAILURE"
};

export default ActionTypes;
