// @ts-check
import {arrayOf, bool, func, object, oneOf, string} from "prop-types";
import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {selectOpRooms as selectOpRoomsDayView} from "../../../../pages/day_view/day_view_selectors";
import {selectOpRooms as selectOpRoomsOpManagement} from "../../../../pages/op_management/op_management_selectors";
import {selectCurrentOrganizationId} from "../../../../redux/app_selectors";
import {isResolved} from "../../../../redux/utils/status";
import checkRoomsFilter from "../../../../utils/check_rooms_filter";
import {loadDisciplineOptionsAction} from "../../../disciplines";
import {selectDisciplinesIdsAndName} from "../../../disciplines/disciplines_selectors";
import {loadRoomsAction} from "../../rooms_actions";
import {selectRoomInfos, selectStatusRooms} from "../../rooms_selectors";
import {RoomsFilterAdvancedForm} from "./components/rooms_filter_advanced_form";
import useStyles from "./rooms_filter_advanced.styles";

/**
 * A component used in the right panel of both DayView, and the OpManagement components
 *
 * @param {object} props
 * @param {"both"|"new"} [props.planSetting] The possible plan settings
 * @param {Function} props.onSave A handler to dispatch the selected filters into the redux store
 * @param {Array<string>} props.disciplinesFilter The list of selected disciplines ids to filter from the redux store
 * @param {Array<string>} props.roomsFilter The list of ids of selected rooms to filter from the redux store
 * @param {Object<string, string[]>} props.disciplineRoomsMapping A dictionary mapping disciplines to op-rooms
 * @param {Array<string>} props.occupiedOpRooms The ids of the occupied rooms
 * @param {boolean} [props.isPlanSettingRequired] Whether the plan's settings are required or not
 * @return {React.ReactElement} The reusable component
 */
export const RoomsFilterAdvanced = ({
    planSetting = "both",
    roomsFilter,
    disciplinesFilter,
    onSave,
    disciplineRoomsMapping,
    occupiedOpRooms,
    isPlanSettingRequired
}) => {
    const {classes} = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const inputRef = useRef(null);

    // Redux
    const organizationId = useSelector(selectCurrentOrganizationId);
    const roomInfos = useSelector(selectRoomInfos);
    const statusRooms = useSelector(selectStatusRooms);
    const disciplinesIdsAndName = useSelector(selectDisciplinesIdsAndName);
    const opRoomsDayView = useSelector(selectOpRoomsDayView);
    const opRoomsOpManagement = useSelector(selectOpRoomsOpManagement);

    const hasNoFilterResultDayView = opRoomsDayView.length === 0;
    const hasNoFilterResultOpManagement = opRoomsOpManagement.length === 0;

    // State
    const defaultMenu = [
        {value: "plannedOps", label: t("RoomsFilterAdvanced.plannedOps")}, // @TODO #14858: should the value be called plannedOps or plannedORs or better naming? Check also naming other modules
        {value: "emptyRooms", label: t("RoomsFilterAdvanced.emptyRooms")},
        {value: "divider", label: "divider"}
    ];
    const [roomsMenuList, setRoomsMenuList] = useState(defaultMenu);
    const [selectedPlanSetting, setSelectedPlanSetting] = useState(planSetting);

    // useEffect
    useEffect(() => {
        if (inputRef && inputRef.current) {
            inputRef.current.focus();
        }
    }, [inputRef]);

    useEffect(() => {
        dispatch(loadRoomsAction(organizationId));
        dispatch(loadDisciplineOptionsAction(organizationId));
    }, []);

    useEffect(() => {
        setRoomsMenuList(defaultMenu.concat(roomInfos.map((room) => ({value: room.id, label: room.name}))));
    }, [roomInfos]);

    if (!isResolved(statusRooms)) {
        return null;
    }

    // Handlers
    /**
     * Save the submitted form data into the redux store
     *
     * @param {RoomsFilterAdvancedFormData} data
     * @return {void}
     */
    const handleSave = (data) => {
        const opRoomsFiltered = checkRoomsFilter(
            data.roomsFilter,
            data.disciplinesFilter,
            occupiedOpRooms,
            disciplineRoomsMapping,
            roomInfos
        );

        onSave({
            planSetting: data.planSetting,
            roomsFilter: data.roomsFilter,
            disciplinesFilter: data.disciplinesFilter,
            opRooms: opRoomsFiltered
        });
    };

    /**
     * Reset all the form values in the redux store
     *
     * @return {void}
     */
    const handleReset = () => {
        const opRoomsIds = roomInfos.map(({id}) => id);
        setSelectedPlanSetting("both");
        onSave({
            planSetting: "both",
            roomsFilter: [],
            disciplinesFilter: [],
            opRooms: opRoomsIds
        });
    };

    // Format the Default Values for the form
    const planList = [
        {label: t("RoomsFilterAdvanced.both"), value: "both"},
        {label: t("RoomsFilterAdvanced.new"), value: "new"}
    ];

    const disciplinesTranslated = disciplinesIdsAndName
        .map((el) => ({value: el.id, label: t([`HealthcareService.${el.id}`, el.name])}))
        .sort((a, b) => a?.label.localeCompare(b?.label, "de", {numeric: true, sensitivity: "base"}));

    const defaultValues = {
        roomsFilter: roomsFilter,
        planSetting: selectedPlanSetting,
        disciplinesFilter: [...disciplinesFilter]
    };

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <div className={classes.title}>{t("RoomsFilterAdvanced.title")}</div>
                <div className={classes.divider} />
                <RoomsFilterAdvancedForm
                    defaultValues={defaultValues}
                    disciplines={disciplinesTranslated}
                    handleReset={handleReset}
                    handleSave={handleSave}
                    hasNoFilterResultDayView={hasNoFilterResultDayView}
                    hasNoFilterResultOpManagement={hasNoFilterResultOpManagement}
                    isPlanSettingRequired={isPlanSettingRequired}
                    planList={planList}
                    roomsMenuList={roomsMenuList}
                />
            </div>
        </div>
    );
};

RoomsFilterAdvanced.propTypes = {
    planSetting: oneOf(["both", "new"]),
    onSave: func.isRequired,
    disciplinesFilter: arrayOf(string).isRequired,
    roomsFilter: arrayOf(string).isRequired,
    disciplineRoomsMapping: object.isRequired,
    occupiedOpRooms: arrayOf(string).isRequired,
    isPlanSettingRequired: bool
};
export default RoomsFilterAdvanced;
