import {t} from "i18next";

import config from "../../../config/config.json";
import {PSEUDO} from "./utils/constants";

/**
 * Build the name in the nextOR standard format. (i.e. Mustermann M.)
 *
 * @param {string} family
 * @param {string[]} given
 * @param {string} id
 * @return {string}
 */
export const buildStandardName = (family, given, id) => {
    // If both of family and given are empty, return unknown
    if (!family && (!given || given.length === 0)) return t("App.unknown");

    const isPseudonym = family === PSEUDO || (given?.length && given[0] === PSEUDO);
    const nbsp = String.fromCharCode(160); // non breaking space

    // @link https://next-data-service.visualstudio.com/13394-nextOR/_wiki/wikis/13394-NextOR-Wiki/1908/FE-details?anchor=category-a%3A-person-related-data
    // Return the value according to the value and environments.
    // - if the value is pseudo and the env is dev, return id
    // - if the value is pseudo and the env is not dev, return "unknown"
    // - if the value is not pseudo return the name
    const isDev = ["local", "remote", "dev", "qa"].includes(global.NODE_ENV);
    if (isPseudonym && isDev) {
        return id;
    }
    if (isPseudonym && !isDev) {
        return t("App.unknown");
    }
    return `${family}${nbsp}${given.map((name) => name.charAt(0).toUpperCase() + ".").join("")}`;
};

/**
 * @typedef {Object} IdAndName
 * @property {string} id
 * @property {string} name
 */

/**
 * Build the surgeon name for DHM
 *
 * @param {IdAndName} surgeon
 * @param {IdAndName} surgeryApprentice
 * @param {IdAndName} mentor
 * @return {string}
 */
export const buildSurgeonName = (surgeon, surgeryApprentice, mentor) => {
    const {
        TEXT_PUNCTUATION: {HYPHEN}
    } = config;
    let surgeonName = HYPHEN;
    if (surgeon.id || surgeryApprentice.id) {
        surgeonName = surgeon.id ? surgeon.name : `${surgeryApprentice.name} (${mentor.name}) `;
    }
    return surgeonName;
};
