import Backdrop from "@mui/material/Backdrop";
import PropTypes from "prop-types";
import React, {Fragment, useContext, useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";

import {DATE_FORMATS, DateContext} from "../../../contexts/dates";
import {reloadRoomPlannerAction} from "../../../pages/room_planner/room_planner_actions";
import {selectReload} from "../../../pages/room_planner/room_planner_selectors";
import {selectCurrentOrganizationId, selectCurrentTimezone} from "../../../redux/app_selectors";
import {isPending, isResolved} from "../../../redux/utils/status";
import sortLocation from "../../../utils/sort_location";
import {selectThemeLocationInfos} from "../../fe_settings/fe_settings_selectors";
import {loadRoomsAction} from "../../rooms/rooms_actions";
import {selectRooms} from "../../rooms/rooms_selectors";
import Loading from "../../shared/loading";
import {loadBlockedRoomsAction} from "../room_planner_actions";
import {selectBlockedRooms, selectStatus} from "../room_planner_selectors";
import useStyles from "./room_planner.styles";
import WeekTable from "./week_table";

const RoomPlanner = ({periodFrom, periodTo, showWeekend, onAdd, onEdit, openRightLayer}) => {
    const {classes} = useStyles();
    const dispatch = useDispatch();
    const {format} = useContext(DateContext);

    // Redux
    const organizationId = useSelector(selectCurrentOrganizationId);
    const currentTimezone = useSelector(selectCurrentTimezone);
    const blockedRooms = useSelector(selectBlockedRooms);
    const rooms = useSelector(selectRooms);
    const status = useSelector(selectStatus);
    const reload = useSelector(selectReload);
    const locationInfos = useSelector(selectThemeLocationInfos); // includes short labels and order

    useEffect(() => {
        if (organizationId) {
            dispatch(
                loadBlockedRoomsAction(
                    organizationId,
                    format(periodFrom, DATE_FORMATS.SYSTEM_DATE),
                    format(periodTo, DATE_FORMATS.SYSTEM_DATE)
                )
            );
            dispatch(loadRoomsAction(organizationId));
        }
    }, [organizationId, periodFrom, periodTo]);

    useEffect(() => {
        if (reload) {
            dispatch(
                loadBlockedRoomsAction(
                    organizationId,
                    format(periodFrom, DATE_FORMATS.SYSTEM_DATE),
                    format(periodTo, DATE_FORMATS.SYSTEM_DATE)
                )
            );
            dispatch(reloadRoomPlannerAction(false));
        }
    }, [reload]);

    const sortedBlockedRooms = useMemo(() => {
        const newBlockedRooms = [...blockedRooms];
        if (isResolved(status)) {
            for (const room of rooms.rooms) {
                const foundRoom = blockedRooms.findIndex((element) => element.id === room.id);
                if (foundRoom === -1) {
                    newBlockedRooms.push({_id: room._id, id: room.id, name: room.name, blockingDates: []});
                }
            }
        }
        return newBlockedRooms.sort(sortLocation(locationInfos, "id"));
    }, [blockedRooms, status]);

    return (
        <Fragment>
            {isPending(status) && (
                <Backdrop className={classes.backdrop} open>
                    <Loading />
                </Backdrop>
            )}
            {isResolved(status) && (
                <WeekTable
                    data={sortedBlockedRooms}
                    from={periodFrom}
                    isPending={isPending(status)}
                    openRightLayer={openRightLayer}
                    showWeekend={showWeekend}
                    timezone={currentTimezone}
                    to={periodTo}
                    onAdd={onAdd}
                    onEdit={onEdit}
                />
            )}
        </Fragment>
    );
};

RoomPlanner.propTypes = {
    periodFrom: PropTypes.object.isRequired, // DateTime
    periodTo: PropTypes.object.isRequired,
    showWeekend: PropTypes.bool,
    onAdd: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    openRightLayer: PropTypes.bool
};

export default RoomPlanner;
