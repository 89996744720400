// @ts-check

import uniq from "lodash/uniq";

/**
 * get ids of patients and practitioners from operations (real, ki, customer, search)
 * @param {Array<PlanBox>} ops
 * @return {Object} {practitionerIds, patientIds}
 */
const getPersonIdsFromScheduleOps = (ops) => {
    const practitionerIds = [];
    const patientIds = [];

    for (const op of ops) {
        if (op?._patient?.id) {
            patientIds.push(op._patient.id);
        }

        if (op._team) {
            Object.entries(op._team).forEach(([category, categoryPracTypes]) => {
                Object.entries(categoryPracTypes).forEach(([pracType, practitionerId]) => {
                    if (practitionerId) {
                        practitionerIds.push(practitionerId);
                    }
                });
            });
        }

        if (op._surgeonPresenting?.reference) {
            practitionerIds.push(op._surgeonPresenting.reference);
        }

        if (op._requestedSurgeon) {
            practitionerIds.push(op._requestedSurgeon);
        }
    }
    return {practitionerIds: uniq(practitionerIds), patientIds};
};

export default getPersonIdsFromScheduleOps;
