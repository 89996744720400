import {makeStyles} from "tss-react/mui";

/** @type {Object} */
export default makeStyles()((theme) => ({
    root: {
        color: theme.palette.grey[900],
        width: "100%",
        position: "relative"
    },
    content: {
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        height: "100%"
    },
    titleText: {
        display: "flex"
    },
    title: {
        ...theme.custom.rightPanel.title
    },
    button: {
        fontSize: "0.875rem",
        fontWeight: theme.typography.fontWeightMedium,
        padding: `${theme.spacing("xs")} 1rem`,
        minWidth: "8rem"
    },
    headerDivider: {
        ...theme.custom.rightPanel.divider
    },
    divider: {
        width: "calc(100% + 5rem)",
        height: "2px",
        position: "relative",
        left: "-2.5rem",
        background: `${theme.palette.grey[100]} 0% 0% no-repeat padding-box`
    },
    icon: {
        top: "0.4rem",
        position: "relative",
        marginRight: theme.spacing("xs")
    },
    formContainer: {
        overflowY: "auto",
        width: "100%",
        height: "100%",
        padding: "0 2.5rem"
    }
}));
